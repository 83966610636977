import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHideBreadCrumbs, setHideFooter } from '../store/pageSlice';
import { parseCookies } from 'nookies';
import Link from 'next/link';
import Head from 'next/head';

const Custom404 = () => {
  const dispatch = useDispatch();
  const [searchInputValue, setSearchInputValue] = useState('');
  const router = useRouter();
  const [message, setMessage] = useState('Page not found');
  const [ctaUrl, setCtaUrl] = useState('/');
  const [ctaText, setCtaText] = useState('Return to home');
  const [searchText, setSearchText] = useState(
    'Search insights, services and colocations'
  );
  const [pageTitle, setPageTitle] = useState('404 Page');

  useEffect(() => {
    dispatch(setHideBreadCrumbs(true));
    dispatch(setHideFooter(true));

    const cookies = parseCookies();
    const cookieData = JSON.parse(cookies?.custom404);
    const message = cookieData?.field_error_message?.[0]?.value;
    const ctaText = cookieData?.field_error_cta_text?.[0]?.value;
    const ctaUrl = cookieData?.field_error_cta_url?.[0]?.value;
    const searchText = cookieData?.field_search_text?.[0]?.value;
    const pageTitle = cookieData?.title;
    setMessage(message);
    setCtaText(ctaText);
    setCtaUrl(ctaUrl);
    setSearchText(searchText);
    setPageTitle(pageTitle);
    return () => {
      dispatch(setHideBreadCrumbs(false));
      dispatch(setHideFooter(false));
    };
  });

  const keyDown = (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    router.push({
      pathname: '/global-search-page',
      query: { search: searchInputValue },
    });
  };

  return (
    <>
      <Head>
        <title>{`Digital Realty | ${pageTitle ?? '404 Page'}`}</title>
      </Head>
      <div className="error-page page-404">
        <div className="content">
          <div className="top">
            <span className="background-number">404</span>
            <span
              className="background-text"
              dangerouslySetInnerHTML={{
                __html: message ? message : 'Page not found',
              }}
            ></span>
            <div className="cta-404">
              <Link href={ctaUrl ?? '/'}>{ctaText ?? 'Return to home'}</Link>
            </div>
          </div>
          <div className="bottom">
            <div className="search-container">
              <img
                alt="search"
                className="search-icon"
                src="/images/search-icon-thick.svg"
              />
              <input
                onChange={(e) => setSearchInputValue(e.target.value)}
                onKeyDown={keyDown}
                value={searchInputValue}
                placeholder={
                  searchText ?? 'Search insights, services and colocations'
                }
              ></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Custom404;
